<template>
  <div>
    <slot
      :add-product-to-card="addProductToCard"
      :change-product-count="changeProductCount"
      :count-added-to-cart="productCount"
      :is-loading="isLoading"
      :is-add-to-cart="productCount > 0"
      name="cartTemplateBtn"
    ></slot>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import useEcommerce from '~/composables/useEcommerceYandex';
import { computed, ref } from '#imports';
import { cartStore } from '#sitis/stores/modules/cart-store';
import type { IUpdateCartItemParams } from '#sitis/stores/modules/cart-store';
import type { IAddItemToCartBody, ICartItem } from '#sitis/internal/controllers/cart/models/CartItem';
import type { IProduct } from '#sitis/internal/controllers/products/models/Product';

const { addEcommerce } = useEcommerce();

export interface IAddToCard {
	isClear: boolean;
}

defineSlots<{
	cartTemplateBtn:(props: {
		addProductToCard: (params: IAddToCard) => void;
		changeProductCount: (newCount: number) => void;
		countAddedToCart: number;
		isLoading: boolean;
		isAddToCart: boolean;
	}) => any;
}>();

const { $ym } = useNuxtApp();
const storeCart = cartStore();
const { items: cartItems } = storeToRefs(storeCart);

const isLoading = ref<boolean>(false);

const props = defineProps<{
	product: IProduct;
	selectedOptions?: { [key: string]: any };
	isResetSettings?: boolean;
}>();

const emits = defineEmits<{
	errorList: [{ [key: number]: string }]
}>();

const productCount = ref<number>(0);
const isAddToCart = computed(() => Boolean(cartItems.value.find((t) => t?.product?.id === props.product.id)));
const isHasOptions = computed(() => props.product?.hasOptions);
const currentCartItem = ref<ICartItem | undefined>();

const countAddedToCart = computed(() => {
  const productFromCart = cartItems.value.find((t) => t?.product?.id === props.product.id);
  return productFromCart?.quantity || 0;
});

// Логика добавления / изменения кол-во товара в корзине
const addProductToCard = async (params: IAddToCard = { isClear: false }) => {
  const { isClear } = params;

  if (isLoading.value || isHasErrors()) {
    return;
  }
  productCount.value = 1;
  isLoading.value = true;

  if (isClear && productCount.value <= 0) {
    removeProductCount();

    productCount.value = 0;
    isLoading.value = false;
    return;
  }

  const productItemCart: IAddItemToCartBody = {
    productId: +props.product.id,
    quantity: 1,
    options: {}
  };

  if (isHasOptions.value) {
    Object.keys((props.selectedOptions || {})).forEach((optionId) => {
			productItemCart.options![optionId] = [props.selectedOptions?.[optionId]?.id];
    });
  }

  currentCartItem.value = await storeCart.addToCart(productItemCart);
  await storeCart.getCartItems();

  if (typeof $ym === 'function') {
    $ym('reachGoal', 'addToCart');
  }

  addEcommerce('add', props.product, { quantity: 1 });
  isLoading.value = false;
};

const changeProductCount = async (newCount: number) => {
  if (isLoading.value) {
    return;
  }
  if (newCount <= 0) {
    productCount.value = 0;

    removeProductCount();
    return;
  }

  isLoading.value = true;

  const productFromCart = currentCartItem.value?.id || '';

  if (productFromCart) {
    const cartItemParams: IUpdateCartItemParams = {
      cartItemId: String(productFromCart),
      quantity: newCount
    };

    const ecommerceType = Boolean(newCount > productCount.value) ? "add" : "remove";

    await storeCart.updateCarItem(cartItemParams);
    await storeCart.getCartItems();
    productCount.value = newCount;
    isLoading.value = false;

    addEcommerce(ecommerceType, props.product, { quantity: 1 });
  }
};

const removeProductCount = () => {
  const productFromCart = currentCartItem.value?.id || '';
  if (productFromCart) {
    storeCart.removeCartItem(String(productFromCart));

    addEcommerce('remove', props.product, { quantity: 1 });
  }
};

const isHasErrors = () => {
  if (!isHasOptions.value) {
    return false;
  }

  const { options } = props.product;
  const selectedOptionsProps = props.selectedOptions || {};
  const errorList: { [key: number]: string } = {};

  options?.forEach((t) => {
    if (!selectedOptionsProps[t.id]) {
      errorList[t.id] = 'Выберите параметр';
    }
  });

  emits('errorList', errorList);
  return Object.keys(errorList).length > 0;
};

watch(() => props.isResetSettings, () => {
  if (props.isResetSettings) {
    productCount.value = 0;
  }
});

// watch(() => props.selectedOptions, () => {
//   isHasErrors();
// }, { deep: true });
</script>

<style>

</style>
